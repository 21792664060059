import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { compose } from 'react-recompose';
import { Button } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import ModalDlg from 'components/Basic/ModalDlg';
import btcImg from 'assets/img/btc.svg';
import cardImg from 'assets/img/card.svg';
import { getCurrency } from 'utilities/common';

const esLocale = require('moment/locale/es');

function OrderDetailModal({ intl, orderDetail, isOpenModal, onCloseModal }) {
  const orderStates = {
    WP: intl.formatMessage({ id: 'WP' }),
    RV: intl.formatMessage({ id: 'RV' }),
    IP: intl.formatMessage({ id: 'IP' }),
    AS: intl.formatMessage({ id: 'AS' }),
    OW: intl.formatMessage({ id: 'OW' }),
    AD: intl.formatMessage({ id: 'AD' }),
    CL: intl.formatMessage({ id: 'CL' }),
    RS: intl.formatMessage({ id: 'RS' }),
    DD: intl.formatMessage({ id: 'DD' }),
    CD: intl.formatMessage({ id: 'CD' }),
    ERR: intl.formatMessage({ id: 'ERR' }),
  };

  if (!orderDetail) {
    return null;
  }

  const OrderDetail = () => {
    return (
      <div className="order_details">
        <div className="group_name">
          <FormattedMessage id="Order details" /> <div className="divider" />
        </div>
        <div className="order_number_elapsed">
          <div>
            <p className="title">
              <FormattedMessage id="Order number" />
            </p>
            <p className="description">#{orderDetail.order || orderDetail.id}</p>
          </div>
          <div>
            <p className="title">
              <FormattedMessage id="Time elapsed" />
            </p>
            <p className="description">En espera</p>
          </div>
        </div>
        <div className="delivery_address">
          <p className="title">
            <FormattedMessage id="Delivery Address" />
          </p>
          <p className="description">{orderDetail?.deliveryAddress?.name || ''}</p>
          <a
            className="sub_description"
            href={`https://www.google.com/maps?z=12&t=m&q=loc:${orderDetail?.deliveryAddress?.lat}+${orderDetail?.deliveryAddress?.long}`}
            target="_blank"
          >
            {orderDetail?.deliveryAddress?.address || ''}
          </a>
        </div>
        <div className="delivery_time">
          <p className="title">
            <FormattedMessage id="Delivery day and time" />
          </p>
          <p className="description">
            {orderDetail.dueTo
              ? `${moment(orderDetail.dueTo).locale('es', esLocale).tz('America/Guatemala').format('LLLL')} horas`
              : ''}
          </p>
        </div>
        <div className="order_name">
          <p className="title">
            <FormattedMessage id="Who to ask for?" />
          </p>
          <div className="customer_description">
            <p>{orderDetail.customer ? orderDetail.customer.firstName : 'Account'}</p>
            <p>{orderDetail.customer ? orderDetail.customer.lastName : 'deleted'}</p>
          </div>
        </div>
      </div>
    );
  };
  const OrderItems = () => {
    return (
      <div className="order_items">
        <div className="group_name">
          <FormattedMessage id="Order elements" /> <div className="divider" />
        </div>
        <div className="item_list">
          {orderDetail.contents &&
            orderDetail.contents.map((order, index) => (
              <div className="item" key={index}>
                <p className="name" dangerouslySetInnerHTML={{ __html: order.item.name }} />
                {order.options.map(({ ingredients = [], aggregates = [], option, price }, index) => (
                  <div key={index}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: option.name + (!price ? '' : ` +${getCurrency().symbol}${(price / 100).toFixed(2)}`),
                      }}
                    ></div>
                    {ingredients
                      .filter(({ quantity }) => quantity === 0 || quantity > 1)
                      .map(({ price, quantity, recipe }) => (
                        <div key={recipe.id}>
                          {`${quantity === 0 ? 'Sin' : 'Extra'} ${recipe.description || ''}${
                            quantity === 0 || !price
                              ? ''
                              : ` +${getCurrency().symbol}${((price / 100) * (quantity - 1)).toFixed(2)}`
                          }`}
                        </div>
                      ))}
                    {aggregates
                      .filter(({ quantity }) => quantity > 0)
                      .map(({ price, quantity, recipe }) => (
                        <div>
                          {`Extra: ${recipe.description || ''}${
                            !price ? '' : ` +${getCurrency().symbol}${((price / 100) * quantity).toFixed(2)}`
                          }`}
                        </div>
                      ))}
                  </div>
                ))}
                <div>
                  {order.ingredients
                    .filter(item => {
                      return item.quantity === 0 || item.quantity > 1;
                    })
                    .map(({ recipe, price, quantity }) => (
                      <div className="additional" key={Math.random()}>
                        {`${quantity === 0 ? 'Sin:' : 'Extra:'} ${recipe.description}${
                          quantity === 0 || !price
                            ? ''
                            : ` +${getCurrency().symbol}${((price / 100) * (quantity - 1)).toFixed(2)}`
                        }`}
                      </div>
                    ))}
                  {order.aggregates
                    .filter(({ quantity }) => quantity > 0)
                    .map(({ recipe, price, quantity }) => (
                      <div className="additional" key={Math.random()}>
                        {`Extra: ${recipe.description} ${
                          !price ? '' : `${getCurrency().symbol}${((price / 100) * quantity).toFixed(2)}`
                        }`}
                      </div>
                    ))}
                </div>
                <p className="amount">
                  {getCurrency().symbol}
                  {parseFloat(order.price / 100).toFixed(2)} x {order.quantity} = {getCurrency().symbol}
                  {parseFloat((order.price * order.quantity) / 100).toFixed(2)}
                </p>
              </div>
            ))}
        </div>
        <div className="order_total">
          <p className="title">
            <FormattedMessage id="Order total" />:
          </p>
          <p className="amount">
            {getCurrency().symbol}
            {parseFloat(orderDetail.paymentAmount / 100).toFixed(2) || ''}
          </p>
        </div>
      </div>
    );
  };
  return (
    <ModalDlg
      className="order_detail_modal"
      visible={isOpenModal}
      width={413}
      top={60}
      handleCancel={() => onCloseModal()}
    >
      {orderDetail && (
        <>
          <div className="detail_content">
            <div className="detail_header">
              <p>
                <FormattedMessage id="Order" /> #{orderDetail.order || orderDetail.id}
              </p>
              <Button className={`${orderDetail.state}`}>{orderStates[orderDetail.state]}</Button>
            </div>
            <OrderDetail />
            <OrderItems />
          </div>
          {(orderDetail.paymentMethod.type === 'C' || orderDetail.paymentMethod.type === 'B') && (
            <div className="detail_footer">
              <p className="pay_way_title">
                <FormattedMessage id="Way to pay" />
              </p>
              <div className="card_info">
                <img src={orderDetail.paymentMethod.type === 'B' ? btcImg : cardImg} alt="card" />
                <div>
                  {orderDetail.paymentMethod.type === 'B' ? (
                    <p>
                      <FormattedMessage id="Paid through Bitcoin Wallet" />
                    </p>
                  ) : (
                    <>
                      <p>
                        <FormattedMessage id="Paid by credit or debit card" />
                      </p>
                      <p>
                        <FormattedMessage id="ending in" /> {orderDetail.paymentMethod.number}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </ModalDlg>
  );
}

OrderDetailModal.defaultProps = {
  isOpenModal: false,
  orderDetail: null,
};

export default compose(injectIntl)(OrderDetailModal);
