import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Tooltip, Button, Pagination, Rate } from 'antd';
import { Apps, RemoveRedEye, CallSplit } from '@material-ui/icons';
import moment from 'moment-timezone';
import { getCurrency } from 'utilities/common';

function OrdersTable({ intl, mode, orderData, total, onChangePage, onSelectOrder }) {
  const orderStates = {
    WP: intl.formatMessage({ id: 'WP' }),
    RV: intl.formatMessage({ id: 'RV' }),
    IP: intl.formatMessage({ id: 'IP' }),
    AS: intl.formatMessage({ id: 'AS' }),
    OW: intl.formatMessage({ id: 'OW' }),
    AD: intl.formatMessage({ id: 'AD' }),
    CL: intl.formatMessage({ id: 'CL' }),
    RS: intl.formatMessage({ id: 'RS' }),
    DD: intl.formatMessage({ id: 'DD' }),
    CD: intl.formatMessage({ id: 'CD' }),
    ERR: intl.formatMessage({ id: 'ERR' }),
  };
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    onChangePage(0, 10);
  }, []);
  useEffect(() => {
    handleChangePage(current, pageSize);
  }, [total]);
  const handleChangePage = (page, size) => {
    const maxPage = Math.ceil(total / size) || 1;
    page = page <= maxPage ? page : maxPage;
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };
  const getFeedback = value => {
    if (value === 1) {
      return 1;
    }
    if (value === 0) {
      return 0.5;
    }
    return 0;
  };

  const feedbackTooltip = feedback => {
    if (feedback) {
      return (
        <div className="feedback_tooltip">
          <div className="food_feedback">
            <FormattedMessage id="How was your food?" />
            <Rate disabled allowHalf count={1} defaultValue={feedback ? getFeedback(feedback.food) : 0} />
          </div>
          <div className="service_feedback">
            <FormattedMessage id="How was the delivery man?" />
            <Rate disabled allowHalf count={1} defaultValue={feedback ? getFeedback(feedback.service) : 0} />
          </div>
          <div className="time_feedback">
            <FormattedMessage id="Did your food arrive on time?" />
            <Rate disabled allowHalf count={1} defaultValue={feedback ? getFeedback(feedback.time) : 0} />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="orders_table_layout">
      {mode === 'table' && (
        <div className="table_header">
          <div className="order">
            <Apps /> # <FormattedMessage id="Order" />
          </div>
          <div className="bought">
            <FormattedMessage id="Bought" />
          </div>
          <div className="delivery_address">
            <FormattedMessage id="Delivery Address" />
          </div>
          <div className="date_time">
            <FormattedMessage id="Date Time" />
          </div>
          <div className="total">
            <FormattedMessage id="Total" />
          </div>
          <div className="qualification">
            <FormattedMessage id="Qualification" />
          </div>
          <div className="actions">
            <FormattedMessage id="Actions" />
          </div>
        </div>
      )}
      <div className="table_content">
        {orderData &&
          orderData.map(item => (
            <div className="table_item" key={Math.random()} onClick={() => onSelectOrder('detail', item)}>
              <Tooltip placement="right" title={orderStates[item.state]}>
                <div className={`status_line order_${item.state}`} />
              </Tooltip>
              <div className="order">
                <p className="code">{item.order || ''}</p>
                <p className="reference_id">{!item.order ? item.id : ''}</p>
                <p className="address">{item.customer ? `${item.customer.firstName} ${item.customer.lastName}` : ''}</p>
                <p className="phone">{item.customer ? item.customer.phone : '-'}</p>
                <p className="email">{item.customer ? item.customer.email : '-'}</p>
              </div>
              <div className="bought">
                <p className="article">
                  {item.contents ? item.contents.length : 0} <FormattedMessage id="articles" />
                </p>
                <div className="device">{item.channel || ''}</div>
              </div>
              <div className="delivery_address">
                <p className="address1">
                  {`${item?.deliveryAddress?.address || ''}, ${item?.deliveryAddress?.addressHouseNumber || ''}, ${
                    item?.deliveryAddress?.description || ''
                  }`}
                </p>
                {item.restaurant &&
                  item.restaurant.zones &&
                  item.restaurant.zones.map(z => (
                    <p className="address2" key={Math.random()}>
                      {z.description || ''}
                    </p>
                  ))}
                {item.restaurant && <p className="product">{item.restaurant.description || ''}</p>}
              </div>
              <div className="date_time">
                <p className="time">{item.dueTo ? moment(item.dueTo).tz('America/Guatemala').format('LL') : ''}</p>
                <p className="note">
                  {item.dueTo
                    ? `Debe ser entregado a las ${moment(item.dueTo).tz('America/Guatemala').format('HH:mm')} horas`
                    : ''}
                </p>
              </div>
              <div className="total">
                <p className="amount">
                  {getCurrency().symbol}
                  {parseFloat(item.paymentAmount / 100).toFixed(2) || ''}
                </p>
                <p className="note">
                  {item.paymentMethod.type === 'C'
                    ? 'CC'
                    : item.paymentMethod.type === 'B'
                    ? 'BTC'
                    : item.paymentMethod.type === 'VL'
                    ? 'VL'
                    : 'COD'}
                </p>
              </div>
              <Tooltip placement="top" title={feedbackTooltip(item.feedback)}>
                <div className="qualification">
                  <Rate
                    disabled
                    count={3}
                    allowHalf
                    defaultValue={
                      item.feedback
                        ? getFeedback(item.feedback.food) +
                          getFeedback(item.feedback.service) +
                          getFeedback(item.feedback.time)
                        : 0
                    }
                  />
                </div>
              </Tooltip>
              <div className="actions">
                <Button className="eye_btn" onClick={() => onSelectOrder('detail', item)}>
                  <RemoveRedEye />
                </Button>
                <Button
                  className="split_btn"
                  onClick={e => {
                    e.stopPropagation();
                    onSelectOrder('sar', item);
                  }}
                >
                  <CallSplit />
                </Button>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination_wrapper">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          current={current}
          pageSize={pageSize}
          pageSizeOptions={['5', '10', '20', '30']}
          total={total}
          onChange={handleChangePage}
          onShowSizeChange={handleShowSizeChange}
        />
        <div className="total">Resultados: {total}</div>
      </div>
    </div>
  );
}

OrdersTable.defaultProps = {
  mode: 'table',
  orderData: [],
  total: 0,
};

export default compose(injectIntl)(OrdersTable);
